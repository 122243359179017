document.addEventListener("DOMContentLoaded", function() {

	if (document.referrer.indexOf('/login/recover/password') > -1) {
		if (document.querySelector('section.login')) {
			document.querySelector('section.login').style.display="none";
		}
		if (document.querySelector('section.forgot-password')) {
			document.querySelector('section.forgot-password').style.display="block";
		}
		
	}

	const usernameField = document.getElementById("username");
	if (usernameField && usernameField.value.length) {
		usernameField.setSelectionRange(usernameField.value.length, usernameField.value.length);
	}

	let loginFormId;
	if (window.edlio && window.edlio.authIsSso) {
		loginFormId = 'superAdminLoginForm';
	} else {
		loginFormId = 'loginForm';
	}

	const loginForm = document.getElementById(loginFormId);

	if (loginForm) {
		addSubmitListenerToLoginForm(loginForm);
	}

	const forgotPasswordForm = document.getElementById('forgotPassWordForm');

	if (forgotPasswordForm) {
		addSubmitListenerToForgotPasswordForm(forgotPasswordForm);
	}
});

function addSubmitListenerToLoginForm(loginForm) {
	loginForm.addEventListener('submit',function(event) {
		if (!window.edlio) {
			event.preventDefault();
			return;
		}

		if (window.edlio.captchaIsActive) {
			event.preventDefault();
		} else {
			return;
		}

		centerRecaptchaImageSelector();

		const adminLoginRecaptchaWidgetIds = window.edlio.adminLoginRecaptchaWidgetIds;

		if (!adminLoginRecaptchaWidgetIds || !Object.keys(adminLoginRecaptchaWidgetIds).length) {
			return;
		}

		const loginWidgetId = !window.edlio.authIsSso ?
			adminLoginRecaptchaWidgetIds.mainWidget : adminLoginRecaptchaWidgetIds.superAdminWidget

		if (loginWidgetId || 0 === loginWidgetId) {
			grecaptcha.execute(loginWidgetId);
		}
	});
}

function addSubmitListenerToForgotPasswordForm(forgotPasswordForm) {
	forgotPasswordForm.addEventListener('submit',function(event) {
		if (!window.edlio) {
			event.preventDefault();
			return;
		}

		if (window.edlio.captchaIsActive) {
			event.preventDefault();
		} else {
			return;
		}

		centerRecaptchaImageSelector();

		const adminLoginRecaptchaWidgetIds = window.edlio.adminLoginRecaptchaWidgetIds;

		if (!adminLoginRecaptchaWidgetIds || !Object.keys(adminLoginRecaptchaWidgetIds).length) {
			return;
		}

		if (adminLoginRecaptchaWidgetIds.passResetWidget || 0 === adminLoginRecaptchaWidgetIds.passResetWidget) {
			grecaptcha.execute(adminLoginRecaptchaWidgetIds.passResetWidget);
		}
	});
}

function adminLoginRecaptchaOnload() {
	if (!window.edlio) {
		return;
	}

	let adminLoginRecaptchaWidgetIds = {};

	if (!window.edlio.authIsSso) {
		const mainRecaptchaContainer = document.querySelector('.main-recaptcha');
		adminLoginRecaptchaWidgetIds.mainWidget = grecaptcha.render(mainRecaptchaContainer);
	} else {
		const superAdminContainer = document.querySelector('.super-admin-recaptcha');
		adminLoginRecaptchaWidgetIds.superAdminWidget = grecaptcha.render(superAdminContainer);
	}

	const passResetRecaptchaContainer = document.querySelector('.pass-reset-recaptcha');
	adminLoginRecaptchaWidgetIds.passResetWidget = grecaptcha.render(passResetRecaptchaContainer);

	window.edlio.adminLoginRecaptchaWidgetIds = adminLoginRecaptchaWidgetIds;

	recaptchaOnload();
}

function recaptchaResetPassOnSubmit(token) {
	const forgotPasswordForm = document.getElementById('forgotPassWordForm');

	recaptchaResponseValidation(forgotPasswordForm, token)
}

function recaptchaOnSubmit(token) {
	if (!window.edlio) {
		return;
	}

	let loginFormId;

	if (window.edlio.authIsSso) {
		loginFormId = 'superAdminLoginForm';
	} else {
		loginFormId = 'loginForm';
	}

	const loginForm = document.getElementById(loginFormId);

	recaptchaResponseValidation(loginForm, token);
}

function recaptchaResponseValidation(form, recaptchaResponse) {
	if (!window.edlio) {
		return;
	}

	if (recaptchaResponse) {
		form.submit();
	} else {
		const formParentDiv = form.parentNode;
		formParentDiv.querySelector('#captcha-failure-notification').style.display = 'flex';
	}
}

function forgotPassword() {
	document.querySelector("section.login").style.display="none";
	document.querySelector("section.forgot-password").style.display="block";

	const notifications = document.querySelectorAll('uniform-notification');
	for (let i = 0; i < notifications.length; i++) {
		notifications[i].style.display="none";
	}
}

function returnSignIn() {
	document.querySelector("section.login").style.display="block";
	document.querySelector("section.forgot-password").style.display="none";
}

function togglePasswordView() {
	const passwordInput = document.getElementById('password1') || document.getElementById('password');
	const showPasswordInput = document.getElementById('show_password');

	if (showPasswordInput.checked) {
		passwordInput.setAttribute("type", "text");
		document.getElementById('show_password_wrapper').innerText="Hide";
		this.forceText = true;
	} else if (!showPasswordInput.checked) {
		passwordInput.setAttribute("type", "password");
		document.getElementById('show_password_wrapper').innerText="Show";
		this.forceText = false;
	}
}

function checkPasswords() {
	const number = /\d/;
	const symbol = /[|\\/~^:,;?!&%$#{}@*+)(_\\.-]/;
	const uppercase = /[A-Z]/;

	const originalPw = document.getElementById("password1").value;
	const submitButton = document.querySelector('.reset-form .submit.button');

	const charLength = document.querySelector('.character-length');
	const stringMatch = document.querySelector('.string-matching');
	const uppercaseMatch = document.querySelector('.uppercase-matching');
	const numberMatch = document.querySelector('.number-matching');
	const symbolMatch = document.querySelector('.symbol-matching');

	const firstName = document.getElementById('pr_fName').value;
	const middleName = document.getElementById('pr_mName').value;
	const lastName = document.getElementById('pr_lName').value;
	const websiteName = document.getElementById('pr_websiteName').value;
	const username = document.getElementById('pr_username').value;
	const position = document.getElementById('pr_position').value;
	const title = document.getElementById('pr_title').value;

	let charLengthPass = false;
	let stringMatchPass = false;
	const uppercaseMatchPass = uppercase.test(originalPw);
	const numberMatchPass = number.test(originalPw);
	const symbolMatchPass = symbol.test(originalPw);

	if (originalPw.length >= 12) {
		charLength.classList.add('pass');
		charLengthPass = true;
	} else {
		charLength.classList.remove('pass');
		charLengthPass = false;
		submitButton.classList.add('disabled')
	}

	if (originalPw.length > 0) {
		const MinimalTermLength = 3;

    const containsKeyTerm = [firstName, middleName, lastName, username, position, title]
      .filter(Boolean)
      .map(item => item.trim().toUpperCase())
      .some(
        (term) => term && term.length > MinimalTermLength
          && originalPw.toUpperCase().includes(term)
      );
    const containsSchoolName = websiteName
      .trim()
      .split(/\s+/)
      .some((term) => term.length > MinimalTermLength
        && originalPw.toUpperCase().includes(term.toUpperCase()));

		if (containsKeyTerm || containsSchoolName) {
				stringMatch.classList.remove('pass');
				stringMatchPass = false;
				submitButton.classList.add('disabled')
		} else if (!containsKeyTerm && !containsSchoolName) {
			stringMatch.classList.add('pass');
			stringMatchPass = true;
		}
	} else {
		submitButton.classList.add('disabled')
		stringMatch.classList.remove('pass');
		stringMatchPass = false;
	}

	if (uppercaseMatchPass) {
		uppercaseMatch.classList.add('pass');
	} else {
		uppercaseMatch.classList.remove('pass');
		submitButton.classList.add('disabled')
	}

	if (numberMatchPass) {
		numberMatch.classList.add('pass');
	} else {
		numberMatch.classList.remove('pass');
		submitButton.classList.add('disabled')
	}

	if (symbolMatchPass) {
		symbolMatch.classList.add('pass');
	} else {
		symbolMatch.classList.remove('pass');
		submitButton.classList.add('disabled')
	}

	if (originalPw.length > 0  && charLengthPass && stringMatchPass && uppercaseMatchPass && numberMatchPass && symbolMatchPass) {
		submitButton.classList.remove('disabled');
	}
}

function superAdminLogin() {
	document.querySelector('.sso-sign-in').classList.add('hide');
	document.querySelector('.sso-super-admin-login').classList.add('override-sso');
}

function changeTypeInput(inputElement) {
	if (!this.forceText) {
		inputElement.type="password" 
	} else {
		inputElement.type="text" 
	}
}